/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import {
    string, shape, array,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/styles';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';

const useStyles = makeStyles((theme) => ({   // eslint-disable-line arrow-parens
    seoBlock: {
        boxSizing: 'border-box',
        maxWidth: '980px',
        margin: '40px auto',
        fontSize: '.8em',
        color: theme.palette.cms?.['Base Font Color'] || theme.palette.cms?.primary || theme.palette.colorPrimary,
        '& h1, h2 ': {
            fontSize: '1.4em',
            textAlign: 'center',
            fontWeight: '400',
        },
        [theme.breakpoints.down(350)]: {
            fontSize: '.6em',
        },
    },

    column: {
        padding: '0 20px',
        '& img': {
            display: 'block',
            width: '100%',
            minHeight: '30px',
            margin: '0 auto',
        },
        '& a': {
            color: `${theme.palette.cms?.primary || theme.palette.colorPrimary}99`,
        },
    },
    seoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 10px',
        whiteSpace: 'nowrap',
        '&:focus': {
            outline: 'none',
            borderBottom: `1px solid ${theme.palette.colorNeutral30}`,
            borderTop: `1px solid ${theme.palette.colorNeutral30}`,
        },
        cursor: 'pointer',
        [theme.breakpoints.down(680)]: {
            display: 'inline-block',
            width: '100%',
        },
    },
    seoHeading: {
        '& h1': {
            display: 'block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        [theme.breakpoints.down(680)]: {
            width: '58%',
            float: 'left',
        },
    },
    seoExpand: ({ gridPresentationBlock }) => ({
        display: 'grid',
        gridTemplateColumns: `repeat(${gridPresentationBlock}, 1fr)`,
        height: 'auto',
        overflowY: 'hidden',
        minHeight: '50px',
        transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        [theme.breakpoints.down(680)]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    }),
    seoToggle: {
        display: 'flex',
        verticalAlign: 'middle',
        marginBlockEnd: '0.67em',
        marginInlineStart: 0,
        marginInlineEnd: 0,
        justifyContent: 'end',
    },
    seoHide: {
        height: '0px',
        overflowY: 'hidden',
    },
    root: {
        width: '100%',
    },
}));

const SeoBlock = ({
    data, presentation_family,
}) => {
    if (!data) return <></>;
    const {
        flourish_image = '',
        markdown = '',
        title = '',
    } = data;

    const gridLength = markdown?.length;

    // showing data as per markdown length
    const gridShowingSEOBlock = (rowNumber) => {
        switch (rowNumber) {
            case 1:
                return 1;
            case 2:
                return 2;
            default:
                return  3;
        }
    };

    const gridPresentationBlock = gridShowingSEOBlock(gridLength);
    const classes = useStyles({ gridPresentationBlock });

    // Use for seo expand only food brand
    const [expanded, setExpanded] = useState(data.collapse);
    const cmsuid = data?._metadata?.uid || null;

    return (
        <>
            {presentation_family === 'food' && gridLength
                ? (
                    <>
                        <div className={`${classes.seoBlock} seoFooter`} container>
                            <div
                                className={classes.root}
                                cmsuid={cmsuid}
                            >
                                <div>
                                    <div className={classes.seoWrapper} onClick={() => { setExpanded(!expanded); }} onKeyDown={() => { }} role="presentation" tabIndex={-1}>
                                        <div className={classes.seoHeading}>{title}</div>
                                        <div aria-hidden="true" className={classes.seoToggle}>{!expanded ? '- Read Less' : '+ Read More'}</div>
                                    </div>
                                    <div role="region" aria-label="Seoblock" className={!expanded ? classes.seoExpand : classes.seoHide} seocolumns="">
                                        {
                                            markdown.map((k, i) => {
                                                const zeroBaseMarkdownLength = gridLength - 1;
                                                const flourishImage = zeroBaseMarkdownLength === i && flourish_image ? <img src={flourish_image.url} alt={flourish_image.description || 'descriptive text bookend graphic'} /> : '';
                                                return <div className={classes.column}><ReactMarkdown escapeHtml={false} source={k} />{flourishImage}</div>;
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                : (
                    markdown?.length > 0 && (
                        <Grid className={classes.seoBlock} container>
                            {
                                markdown.map((k, i) => {
                                    const zeroBaseMarkdownLength = gridLength - 1;
                                    const flourishImage = zeroBaseMarkdownLength === i && flourish_image ? <img src={flourish_image.url} alt={flourish_image.description || 'descriptive text bookend graphic'} /> : '';
                                    return <div className={classes.column}><ReactMarkdown escapeHtml={false} source={k} />{flourishImage}</div>;
                                })
                            }
                        </Grid>
                    )
                )}
        </>
    );
};

SeoBlock.propTypes = {
    data: shape({
        seo_copy: shape({
            flourish_image: shape({
                title: string,
                url: string,
            }).isRequired,
            markdown: array,
            title: string,
        }),
    }),
    presentation_family: string.isRequired,
};

SeoBlock.defaultProps = {
    data: {},
};
const mapStateToProps = (state) => ({
    presentation_family: getPresentationFamily(state),
    ssrDeviceType: getSSRDeviceType(state),

});
const enhance = compose(
    connect(mapStateToProps),
);

export default enhance(SeoBlock);
