/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    array,
    shape, string,
} from 'prop-types';
import React from 'react';
import SeoBlockV2 from '../SeoBlockV2/SeoBlockV2';
import SeoBlock from './SeoBlock';

const SeoBlockContainer = ({ data }) => {
    if (Object.keys(data).length < 0) return null;
    const presentationStyle = data?.presentation_type;

    switch (presentationStyle) {
        case 'default':
            return <SeoBlock data={data} />;
        case 'v2':
            return <SeoBlockV2 seoBlock={data} />;
        default:
            return <SeoBlock data={data} />;
    }
};

SeoBlockContainer.propTypes = {
    data: shape({
        title: string,
        presentation_type: string,
        markdown: array,
    }),
};

SeoBlockContainer.defaultProps = {
    data: {},
};

export default SeoBlockContainer;
