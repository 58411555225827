/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    array,
    shape, string,
} from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { useUIDSeed } from 'react-uid';
import { Add, Remove } from '@material-ui/icons';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    seoV2Container: {
        width: '100%',
        maxWidth: '980px',
        margin: '2em auto',
        [theme.breakpoints.down(1080)]: {
            width: '100%',
            maxWidth: '768px',
            padding: '0px 12px',
        },
        [theme.breakpoints.down(768)]: {
            width: '100%',
            padding: '0px 27px',
        },
    },
    mainContainerGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        width: '100%',
        margin: '0 auto',
        [theme.breakpoints.down(768)]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },
    accordionHeading: {
        fontSize: '1.1em',
        fontFamily: theme.typography?.fontFamily,
    },
    accordionContainer: {
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.primary.main || '#2F2F2F'}`,
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '10px 0px',
        },
        '& .MuiAccordionDetails-root': {
            padding: '0px',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            borderBottom: `2px solid ${theme.palette.primary.main || '#2F2F2F'}`,
            minHeight: '54px',
        },
        '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
            '& $addIcon': {
                clip: 'rect(0 0 0 0)',
                overflow: 'hidden',
                position: 'absolute',
            },
        },
        '& .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon': {
            '& $removeIcon': {
                clip: 'rect(0 0 0 0)',
                overflow: 'hidden',
                position: 'absolute',
            },
        },
        '&.MuiAccordion-root.Mui-expanded': {
            borderBottom: 'none',
            margin: '0px',
        },
        '&.MuiAccordion-root': {
            '&::before': {
                content: 'none',
            },
        },
        '& .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon.Mui-expanded': {
            '& $removeIcon': {
                clip: 'rect(0 0 0 0)',
                overflow: 'auto',
                position: 'static',
            },
        },
    },
    summaryContainer: {
        padding: '0px',
        position: 'relative',
    },
    removeIcon: {
        border: `1px solid ${theme.palette.primary.main || '#2F2F2F'}`,
        borderRadius: '50%',
        background: theme.palette.bgContrastPrimaryColor || '#E8F1ED',
    },
    addIcon: {
        border: `1px solid ${theme.palette.primary.main || '#2F2F2F'}`,
        borderRadius: '50%',
    },
    detailsContainer: {
        paddingRight: '15px',
        '& *': {
            fontSize: '1em',
            fontWeight: 400,
            color: theme.palette.primary.main || '#2F2F2F',
            [theme.breakpoints.down(768)]: {
                fontSize: '0.9em',
            },
        },
        '& p,ul,a': {
            fontSize: '0.8em',
            color: theme.palette.primary.main || '#2F2F2F',
        },
        '& li': {
            padding: '2px 0px',
        },
        '& ul': {
            margin: '0px',
            paddingLeft: '27px',
        },
        [theme.breakpoints.down(680)]: {
            paddingRight: '0px',
        },
    },

}));

const SeoBlockV2 = ({ seoBlock }) => {
    const classes = useStyles();
    const seed = useUIDSeed();
    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : !seoBlock.collapse);
    };

    return (
        <div className={classes.seoV2Container}>
            <Accordion
                square
                className={classes.accordionContainer}
                expanded={expanded === seoBlock.collapse}
                onChange={handleChange(seoBlock.collapse)}
            >
                <AccordionSummary
                    className={classes.summaryContainer}
                    aria-controls="panel-content"
                    id="panel-header"
                    expandIcon={(
                        <>
                            <Remove className={classes.removeIcon} />
                            <Add className={classes.addIcon} />
                        </>
                    )}
                >
                    <span className={classes.accordionHeading}>{seoBlock?.title}</span>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.mainContainerGrid}>
                        {
                            seoBlock?.markdown?.map((seoData) => (
                                <div key={seed(seoData)} className={classes.detailsContainer}>
                                    <ReactMarkdown source={seoData} skipHtml={false} />
                                </div>
                            ))
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

SeoBlockV2.propTypes = {
    seoBlock: shape({
        title: string,
        presentation_type: string,
        markdown: array,
    }),
};

SeoBlockV2.defaultProps = {
    seoBlock: {},
};

export default SeoBlockV2;
